import NilsIMG from '../assets/img/team/Nils.png';
import JulieIMG from '../assets/img/team/Julie.png';
import NevenaIMG from '../assets/img/team/Nevena.png';
import NamitoIMG from '../assets/img/team/Namito.png';
import EvaIMG from '../assets/img/team/Eva.png';
import MurielIMG from '../assets/img/team/Muriel.png';
import HannahIMG from '../assets/img/team/Hannah.png';

import { TeamMember } from './TeamMember'

interface TeamMember {
    name: string;
    positions: string[];
    positions_de?: string[];
    image: string;
}

let teamMembers: TeamMember[] = [
    {
        name: "Julie",
        positions: ["CEO & Founder", "Managing Director"],
        positions_de: ["CEO & Founder", "Geschäftsführerin"],
        image: JulieIMG
    },
    {
        name: "Nils",
        positions: ["COO & Co-Founder", "Managing Director"],
        positions_de: ["COO & Co-Founder", "Geschäftsführer"],
        image: NilsIMG
    },
    {
        name: "Namito",
        positions: ["Creative Head of Technology"],
        image: NamitoIMG
    },
    {
        name: "Eva",
        positions: ["Supply Chain Management"],
        image: EvaIMG
    }, 
    {
        name: "Muriel",
        positions: ["Designer"],
        image: MurielIMG
    },
    {
        name: "Hannah",
        positions: ["Executive Assistant"],
        image: HannahIMG
    }
]

function TeamContent(props: any) {
    const {textJulie, textNils, selectedLanguage} = props;

    return(
    <>
        <div className='team'>
            <div>
                <TeamMember element={teamMembers[0]} selectedLanguage={selectedLanguage} key="t-m-0"/>
                <p>
                    <b>Julie</b> {textJulie}
                </p>
                </div>
            <div>
                <TeamMember element={teamMembers[1]} selectedLanguage={selectedLanguage} scaleDown={true} key="t-m-1"/>
                <p>
                    <b>Nils</b> {textNils}
                </p>
            </div>
        </div>
        <div className='team'>
            <TeamMember element={teamMembers[2]} scaleDown={true} key="t-m-2"/>
            <TeamMember element={teamMembers[3]} key="t-m-3"/>
        </div>
        <div className='team'>
            <TeamMember element={teamMembers[4]} key="t-m-4"/>
            <TeamMember element={teamMembers[5]} scaleUp={true} key="t-m-5"/>
        </div>
        <div className='team'>
        </div>
    </>
    )
}

export { TeamContent };