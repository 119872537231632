import React, { useEffect, useMemo, useState } from 'react';
import headerMenu from './assets/svg/header-menu.svg';
import { Routes, Route, Link, Navigate, useLocation, useParams } from "react-router-dom";

import { motion } from "framer-motion";


import { calculateTimeLeftTillNextDrop } from "./data/countdown";

import { roadmapData } from "./data/roadmap";

import ComingSoon from "./assets/img/header/coming-soon.png";

/*import backgroundHome001 from './assets/img/background/001.png';
import backgroundHome002 from './assets/img/background/002.png';
import backgroundHome003 from './assets/img/background/003.png';
import backgroundHome004 from './assets/img/background/004.png';*/

import backgroundHome001 from './assets/img/background/001.webp';
import backgroundHome002 from './assets/img/background/002.webp';
import backgroundHome003 from './assets/img/background/003.webp';
import backgroundHome004 from './assets/img/background/004.webp';

import videoBackgroundMobile from './assets/img/video_background.mp4';
import videoBackground from './assets/img/video_background_mid.mp4';

import { isMobile } from "react-device-detect";

import lang_toggle_en from './assets/svg/lang_en.svg';
import lang_toggle_de from './assets/svg/lang_de.svg';

import volumeHighIcon from './assets/svg/volume-high.svg'
import volumeMuteIcon from './assets/svg/volume-mute.svg'
//import musicBackground from './assets/music/WearYourArt.mp3'
import musicBackground from './assets/music/WearYourArt.aac'

import { Menu } from './components/Navigation/Menu'
import { LogoHeader } from './components/Logo';
import { LatestDrops } from './components/LatestDrops';
import { LogoBanner } from './components/LogoBanner';
import { WhatIsJMES } from './WhatIsJMES';
import { HowDoesItWorkChart } from './components/HowDoesItWork';

import { ImpactContent } from './components/Impact';

import { TeamContent } from './components/Team';

import { ImprintContent } from './components/Imprint';
import { DataPrivacyContent } from './components/DataPrivacy';

import { NftContent } from './components/Nft';
//import * as data from './data/uuids.json';
import nftData from './assets/uuids.json';

import dictionary from './assets/language.json';

import { SectionHeader } from './components/SectionHeader'

import './App.scss';

let selectedLanguage : "de" | "en" = localStorage.getItem('language') as "de" | "en" || "en";

function App() {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeftTillNextDrop());
  const [isLoading, setLoading] = useState(true);
  const audio = useMemo(() => new Audio(musicBackground), []);
  const [playing, setPlaying] = useState(false);
  

  const toggleAudio = () => { setPlaying(!playing); !playing ?  audio.play() : audio.pause();}

  localStorage.setItem('language', selectedLanguage);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeftTillNextDrop());

      return () => clearTimeout(timer);
    }, 1000);
  });

  window.addEventListener('load', (event) => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  });
  
  return (
    <div className="App">
      {
        <>
          {
            isLoading ?
            <div className='loading'>
              <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 100 100" enable-background="new 0 0 0 0" xmlSpace="preserve">
                  <path fill="#fff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                </path>
              </svg>
            </div>
            :
            ''
          }
          <Menu />
          <motion.img 
          src={selectedLanguage === "en" ? lang_toggle_en : lang_toggle_de}  
          className='lang-button'
          transition={{ duration: 0.1}}
          whileTap={{ scale: 0.9 }}
          onClick={() => {selectedLanguage = selectedLanguage === "en" ? "de" : "en"; localStorage.setItem('language', selectedLanguage);}}/>
          {
            playing ?
              <motion.img 
              src={volumeHighIcon} 
              onClick={toggleAudio} 
              className='music-button' 
              id="music-pause"
              transition={{ duration: 0.1}}
              whileTap={{ scale: 0.9 }}>
              </motion.img> 
            : 
              <motion.img 
              src={volumeMuteIcon} 
              onClick={toggleAudio} 
              className='music-button' 
              id="music-play"
              transition={{ duration: 0.1}}
              whileTap={{ scale: 0.9 }}>
              </motion.img>
          }
          <div className='header'>
            <div className='next-drop-timer'>
              { dictionary.home.next_drops.title_singular[selectedLanguage] }: 
              {/* <span className='time'>{timeLeft?.days + ":" + timeLeft?.hours + ":" + timeLeft?.minutes + ":" + timeLeft?.seconds}</span> */}
              <span className='time'>Coming soon</span>
            </div>
        </div>
        </>
      }


      <Routes>
        <Route path="/" element={<Home timeLeft={timeLeft} />} />
        <Route path="team" element={<Team />} />
        <Route path="impact" element={<Impact />} />
        {/* <Route path="world" element={<World />} /> */}
        <Route path="events" element={<Events />} />
        {/* <Route path="meta" element={<Meta />} /> */}
        <Route path="imprint" element={<Imprint />} />
        <Route path="privacy" element={<DataPrivacy />} />
        <Route path="nft" element={<Home timeLeft={timeLeft} />} />
        <Route path="nft/:id" element={<Nft />} />
      </Routes>

        <div className='footer'>
        {/* <a>
          NFT
        </a> */}
        <Link to="/team">
          { dictionary.global.menu.team[selectedLanguage] }
        </Link>
        <Link to="/impact">
        { dictionary.global.menu.impact[selectedLanguage] }
        </Link>
        <Link to="/imprint">
        { dictionary.global.menu.imprint[selectedLanguage] }
        </Link>
        <Link to="/privacy">
        { dictionary.global.menu.privacy[selectedLanguage] }
        </Link>
      </div>

    </div>
  );
}

function Home(props: any) {
  const {timeLeft} = props;
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const pauseAudio = () => {
    try {
      document.getElementById("music-pause")!.click();
    } catch(e) {
      // music is not playing
    }
  }

  return (
    <>
      <main id="home">
        <img src={backgroundHome001}  className='background-home-001'/>
        <img src={backgroundHome002}  className='background-home-002'/>
        <img src={backgroundHome003}  className='background-home-003'/>
        <img src={backgroundHome004}  className='background-home-004'/>

        <section className='full-height full-width'>
        <div className='video-backdrop'></div>
        <video muted loop id="video-bg" autoPlay playsInline>
          {isMobile ? <source src={videoBackgroundMobile} type="video/mp4" /> : <source src={videoBackground} type="video/mp4" />}
        </video>
          <LogoHeader />
          <div className='hero-buttons'>
            <a href="https://meta.jmesworld.com/" target="_blank">
              <button className='hero' onClick={() => pauseAudio()}>
                Meta world
              </button>
            </a>
            {/* <button className='hero'>
              Shop
            </button> */}
          </div>
        </section>

        <section id="latest-drops">
          <SectionHeader sectionTitle="NextDrops" selectedLanguage={selectedLanguage} />
          <LatestDrops />
        </section>

        <section id="what-is-james">
          <SectionHeader sectionTitle="WhatIsJMES" selectedLanguage={selectedLanguage} />
          <div className='content'>
            <p>
              { dictionary.home.what_is_jmes.description[selectedLanguage] }
            </p>
            {/* <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.3 }}
            variants={{
              visible: { opacity: 1, scale: 1 },
              hidden: { opacity: 0, scale: 0 }
            }}
          >
            <img src={content001}/>            
          </motion.div> */}

          <WhatIsJMES />
          </div>
        </section>

        <section id="how-does-it-work">
        <SectionHeader sectionTitle="HowDoesItWork" selectedLanguage={selectedLanguage} />
          <div className='content'>
            <p dangerouslySetInnerHTML={{__html: dictionary.home.how_does_it_work.description[selectedLanguage]}} />
            <HowDoesItWorkChart language={selectedLanguage} />
          </div>
        </section>

        <LogoBanner scrollPosition={scrollPosition}/>

        <section id="next-drop-timer">
          <SectionHeader sectionTitle="NextDrop" selectedLanguage={selectedLanguage} />
          {/* <div className='content'>
            <div className='element'>
              <h1 className='timeLeftDigit'>
                { timeLeft?.days }
              </h1>
              <h2>
                { dictionary.home.next_drops.time.days[selectedLanguage] }
              </h2>
            </div>
            <div className='element'>
              <h1 className='timeLeftDigit'>
                { timeLeft?.hours }
              </h1>
              <h2>
              { dictionary.home.next_drops.time.hours[selectedLanguage] }
              </h2>
            </div>
            <div className='element'>
              <h1 className='timeLeftDigit'>
                { timeLeft?.minutes }
              </h1>
              <h2>
              { dictionary.home.next_drops.time.minutes[selectedLanguage] }
              </h2>
            </div>
            <div className='element'>
              <h1 className='timeLeftDigit'>
                { timeLeft?.seconds }
              </h1>
              <h2>
              { dictionary.home.next_drops.time.seconds[selectedLanguage] }
              </h2>
            </div>
          </div> */}

          <h1 style={{color: 'white', fontSize: '80px', letterSpacing: '0.05em'}}>
            <img src={ComingSoon} style={{marginBottom: "-110px"}} />
          </h1>
        </section>

        <section id="whats-next">
          <SectionHeader sectionTitle="WhatsNext" selectedLanguage={selectedLanguage} />
          <div className='cards'>
          {
            dictionary.home.whats_next.entries[selectedLanguage].map(
              card => (
              <div className='card' key={card.title}>
              <h2>
                { card.title }
              </h2>
              {
                card.content.map((text, index) => (
                <p key={'roadmap-card-' + index}>
                  { text }
                </p>
                ))
              }

            </div>
              )
            )
          }
          </div>
        </section>
      </main>
      {/* <nav>
        <Link to="/about">About</Link>
      </nav> */}
    </>
  );
}

function Team() {
  return (
    <main id="team">
      <img src={backgroundHome001}  className='background-team-001'/>
      <img src={backgroundHome002}  className='background-team-002'/>

      <SectionHeader sectionTitle="Team" selectedLanguage={selectedLanguage} isPageHeader={true} />

      <section id="team">
        <TeamContent textJulie={dictionary.team.description_julie[selectedLanguage]} textNils={dictionary.team.description_nils[selectedLanguage]} selectedLanguage={selectedLanguage} />
      </section>
    </main>
  );
}

function World() {
  return (
    <main id="world">
      <img src={backgroundHome002}  className='background-world-002'/>
      <section id="world">
          
          <SectionHeader sectionTitle="Events" selectedLanguage={selectedLanguage} isPageHeader={true} />
          <div className='content'>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/5ZUtWOWGxDQ" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen frameBorder="0"></iframe>
            <p className='text-justify'>{ dictionary.world.text[selectedLanguage] }</p>
          </div>
      </section>
    </main>
  );
}

function Meta() {
  return(
    <>
    <iframe src="https://meta.jmesworld.com" className='metaFrame'>
      <a href="https://meta.jmesworld.com/">Please visit the JMES Metaworld trough meta.jmesworld.com</a>
    </iframe>
    </>
  )
}


function Impact() {
  return (
    <main id="impact">
      <img src={backgroundHome003}  className='background-impact-003'/>

      <SectionHeader sectionTitle="Impact" selectedLanguage={selectedLanguage} isPageHeader={true} />

      <section id="impact">
        <ImpactContent impactEntries={dictionary.impact.entries[selectedLanguage]} />
      </section>
    </main>
  );
}


function Events() {
  return (
    <main id="events">
      <section id="events">
      <img src={backgroundHome002}  className='background-events-002'/>
      <SectionHeader sectionTitle="JMESWorld" selectedLanguage={selectedLanguage} isPageHeader={true} />
        <div className='cards'>
          {/* <div className='card'>
            <h2>{ dictionary.events.entries[selectedLanguage][1].title }</h2>
            <p>{ dictionary.events.entries[selectedLanguage][1].description }</p>
          </div> */}
          <div className='card'>
            <h2>{ dictionary.events.entries[selectedLanguage][0].title }</h2>
            <p>{ dictionary.events.entries[selectedLanguage][0].description }</p>
            <iframe width="100%" height="315" className="yt-frame" src="https://www.youtube.com/embed/5ZUtWOWGxDQ" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen frameBorder="0"></iframe>
          </div>
        </div>
      </section>
    </main>
  );
}

function Imprint() {
  return (
    <>
      <section id="imprint">
        <SectionHeader sectionTitle="Imprint" selectedLanguage={selectedLanguage} isPageHeader={true} />
        <ImprintContent language={selectedLanguage} />
      </section>
    </>
  );
}

function DataPrivacy() {
  return (
    <>
      <section id="privacy">
        <SectionHeader sectionTitle="Privacy" selectedLanguage={selectedLanguage} isPageHeader={true} />
        <DataPrivacyContent language={selectedLanguage} />
      </section>
    </>
  );
}

function Nft() {
  return (
    <main id="nft">
      <img src={backgroundHome001}  className='background-home-001'/>
        <img src={backgroundHome002}  className='background-home-002'/>
        <img src={backgroundHome003}  className='background-home-003'/>
        <img src={backgroundHome004}  className='background-home-004'/>
      <section id="nft">
        <SectionHeader sectionTitle="NFT" selectedLanguage={selectedLanguage} isPageHeader={true} />
        <NftContent language={selectedLanguage} id={useParams()} nftData={nftData.packs[0]} />
      </section>
      </main>
    
  );
}


export default App;
