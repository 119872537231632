import * as React from "react";
import { useRef } from "react";
import { motion, useCycle } from "framer-motion";
import { useDimensions } from "../../hooks/useDimensions";
import { MenuToggle } from "./MenuToggle";
import { Navigation } from "./Navigation";
import './Menu.scss';

function Menu() {
    const sidebar = {
        open: (height = window.innerHeight * 1.2) => ({
          clipPath: `circle(${height * 2 + 200}px at 95% 30px)`,
          opacity: 1,
          transition: {
            delay: 0.1,
            type: "spring",
            stiffness: 20,
            restDelta: 2
          }
        }),
        closed: {
          clipPath: "circle(30px at 95% 30px)",
          opacity: 0,
          transition: {
            delay: 0.5,
            type: "spring",
            stiffness: 400,
            damping: 40
          }
        }
      };

    const [isOpen, toggleOpen] = useCycle(false, true);
    const containerRef = useRef<HTMLInputElement>(null);
    const { height } = useDimensions(containerRef);
  
    const toggleScroll = () => {
        // document.body.style.overflow = "hidden"
        if(isOpen) {
            document.body.style.overflow = "hidden";

            if(containerRef.current)
                containerRef.current!.style.height = "100vh";

        } else {
            document.body.style.overflow = "initial";

            if(containerRef.current)
                setTimeout(() => {
                    containerRef.current!.style.height = "55px";
                }, 1000);
        }
    }

    return (
        <>
            <motion.nav
            initial={false}
            animate={isOpen ? "open" : "closed"}
            custom={height}
            ref={containerRef}
            >
            <motion.div className="background" variants={sidebar} onClick={() => {toggleOpen(); toggleScroll()}} />
            <Navigation />
            <MenuToggle toggle={() => toggleOpen()} onClick={toggleScroll()} />
            </motion.nav>
        </>
    );
}

export { Menu };