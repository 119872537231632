import logo from '../assets/img/logo_.png';
import { motion, useAnimation } from "framer-motion";

function LogoHeader() {
    const variants = {
        visible: { opacity: 1, translateY: 0 },
        hidden: { opacity: 0, translateY: 50 }
      };
      
    const transitions = [
        {
            duration: 1
        },
        {
            duration: 1,
            delay: 0.8
        }
      ]
    return (
        <>
            <div className='logo'>
                <motion.img src={logo} 
                initial="hidden"
                animate="visible"
                transition={transitions[0]}
                variants={variants}/>
                <motion.h2 
                initial="hidden"
                animate="visible"
                transition={transitions[1]}
                variants={variants}>
                    WEAR YOUR ART
                </motion.h2>
            </div>
        </>
    );
}

export { LogoHeader };