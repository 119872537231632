function ImprintContent(props: any) {
    const {language} = props;

    return(
        <>
        {
            language === "en" ?
            <>
                <p>
                Information according to §§ 5 and 6 Telemediengesetz (TMG)
                </p>
                <br />
                <p>
                <br />JMES World GmbH
                <br />Tempelhofer Ufer 17
                <br />10963 Berlin
                <br />Germany
                </p>

                <p>
                Managing Directors:
                <br />Julie-Madeleine Schloemer
                <br />Sabrina Heimig-Schloemer
                <br />Nils Konstantin Hölterhoff
                </p>

                <p>
                Registered at Amtsgericht Charlottenburg
                <br />HRB 232434 B
                <br />USt-ID: DE345487708
                <br />
                <br />Phone: +49 160 97902499
                <br />Email: info@jmesworld.com
                </p>
            </>
        : 
       
        <>
                <p>
                Informationen nach §§ 5 und 6 Telemediengesetz (TMG)
                </p>
                <br />
                <p>
                <br />JMES World GmbH
                <br />Tempelhofer Ufer 17
                <br />10963 Berlin
                <br />Deutschland
                </p>

                <p>
                GeschäftsführerInnen:
                <br />Julie-Madeleine Schloemer
                <br />Sabrina Heimig-Schloemer
                <br />Nils Konstantin Hölterhoff
                </p>

                <p>
                Eingetragen bei Amtsgericht Charlottenburg
                <br />HRB 232434 B
                <br />USt-ID: DE345487708
                <br />
                <br />Telefon: +49 160 97902499
                <br />Email: info@jmesworld.com
                </p>
        </>
  
        }
    </>
        
    );
}

export { ImprintContent };