import * as React from "react";
import { motion } from "framer-motion";
import { MenuItem } from "./MenuItem";
import Home from "./../../assets/img/menu/Home.png"
// import NFT from "./../../assets/img/menu/NFT.svg"
// import Shop from "./../../assets/img/menu/Shop.svg"
import Events2 from "./../../assets/img/menu/Events2.png"
import Impact2 from "./../../assets/img/menu/Impact2.png"
import Team from "./../../assets/img/menu/Team.png"
import JMESWorld from "./../../assets/img/menu/JMES-world.png"

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.5 }
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
};

export const Navigation = () => (
  <motion.ul variants={variants}>
    {items.map((info, index) => (
      <MenuItem info={info} key={'menu-item'+ index} />
    ))}
  </motion.ul>
);

const items = [{url: "/", src: Home}, {url: "/events", src: Events2}, {url: "/impact", src: Impact2}, {url: "/team", src: Team}];
