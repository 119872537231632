
import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { LATEST_DROPS_ELEMENTS } from "../data/latest-drops";

function LatestDrops() {
    const controls = useAnimation();
    const { ref, inView } = useInView();
    
    useEffect(() => {
        if (inView) {
          controls.start('visible');
        }
      }, [controls, inView]);

    const variants = {
        visible: { opacity: 1, translateY: 0 },
        hidden: { opacity: 0, translateY: 50 }
      };


    return(
        <>
            <div 
            className='latest-drops' 
            id="latest-drops"
            ref={ref}>
                { LATEST_DROPS_ELEMENTS.map((element, index) => (
                    <a href={element.url} target={element.url !== "#latest-drops" ? '_blank' : '_self'}>
                        <motion.div 
                        className='latest-drops-element' 
                        key={element.title}
                        
                        initial="hidden"
                        animate={controls}
                        transition={{ duration: 0.8, delay: (index * 0.8)}}
                        variants={variants}
                        >
                            <motion.div
                                transition={{ duration: 0.3}}
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}>
                                <video src={element.image} autoPlay muted loop playsInline />
                                <h2>
                                    { element.title }
                                </h2>
                            </motion.div>

                        </motion.div>
                    </a>
                ))}
          </div>
        </>
    )
}

export {LatestDrops};