function DataPrivacyContent(props: any) {
    const {language} = props;

    return(
    <>
    {
        language === "en" ? 
        <>
        <p className="text-justify">In this privacy policy statement, we, the JMES World GmbH, inform you about the processing of personal data when using our website.</p>
        <p className="text-justify">Personal data is information that relates to an identified or identifiable person. This includes, above all, information that makes it possible to draw conclusions about your identity, such as your name, telephone number, address or e-mail address. Statistical data that we collect, for example, when you visit our website and that cannot be associated with your person, is not covered by the term “personal data“.</p>
        <br />
        <h2>
            Contact Person
        </h2>
        <p className="text-justify">
        Contact person and so-called person responsible for the processing of your personal data when visiting this website within the meaning of the EU General Data Protection Regulation (“GDPR”) is
        <br />JMES World GmbH
        <br />Rheinsberger Str. 76/77
        <br />10115 Berlin
        <br />E-Mail:info@jmesworld.com
        <br />If you have any questions about privacy in connection with our products or the use of our website, you can always contact our data protection officer. Our data protection officer can be reached at the above mentioned postal address as well as at the previously stated e-mail address (keyword: “for the attention of the data protection officer”).
        </p>

        <h2>
            Data Processing on our website
        </h2>
        <h3>
            1. Access of our website / Access data
        </h3>
        <p className="text-justify">
            Every time we use our website, we collect the access data that your browser automatically transmits to enable you to visit the website. The access data includes in particular:


        <br />IP address of the requesting device;
        <br />date and time of request;
        <br />address of the website and the requesting website;
        <br />information about the used browser and the operating system;
        <br />online identifiers (e.g. device IDs, session IDs).
        </p>

        <p className="text-justify">
            The data processing of this access data is necessary to enable the visit of the website and to ensure the long-term functionality and security of our systems. The access data is also temporarily stored in internal log files for the purposes described above to provide statistical information on the use of our website in order to further develop our website in terms of the usage habits of our visitors (for example, as the share of mobile devices increases, with which the pages are retrieved) and to maintain our website in general administration. The legal basis of this is Art. 6 para. 1 sentence 1 lit. b GDPR.
        <br />The information stored in the log files does not allow any direct inference to your person – in particular, we store the IP addresses only in a shortened, anonymized form. The log files are storedfor thirty (30) days and are archived after subsequent anonymization.
        </p>

        <h3>
            2. Contact/Communication
        </h3>
        <p className="text-justify">
            You have different ways to get in touch with us. This includes the contact form or the possibility to send an e-mail. In this context, we process data solely for the purpose of communicating with you. The legal basis of this is Art. 6 para. 1 sentence 1 lit. b GDPR. The data collected by us when using the contact form will be automatically deleted after full processing of your request, unless we still need your request for the fulfillment of contractual or legal obligations (see section “Storage period“).
        </p>

        <h3>
        3. Usage of Cookies and comparable technologies for analysis purposes
        </h3>
        <p className="text-justify">
            We does not use any cookies or analytic tools.
        </p>

        <h2>
            Transfer of Data
        </h2>
        <p className="text-justify">
        A transfer of the data collected by us takes place only if:
        <br />you are required under Art. 6 para. 1 sentence 1 lit. a GDPR to have given your explicit consent;
        <br />disclosure pursuant to Art. 6 para. 1 sentence 1 lit. f GDPR is required to assert, exercise or defend legal claims and there is no reason to believe that you have an overriding interest in not disclosing your data;
        <br />in accordance with Art. 6 para. 1 sentence 1 lit. c GDPR you are required by law to disclose; or
        <br />this is legally permissible and according to Art. 6 para. 1 sentence 1 lit. b GDPR this is required for the execution of contractual relationships with you or for the execution of pre-contractual measures, which are carried out at your request.
        <br />
        <br />Part of the data processing can be done by our service providers. In addition to the service providers mentioned in this privacy policy, this may include, but is not limited to, data centers that maintain our website and databases, IT service providers who maintain our systems, and consulting firms. If we pass on data to our service providers, they may only use the data to fulfill their tasks. The service providers were carefully selected and commissioned by us. They are contractually bound by our instructions, have appropriate technical and organizational measures to protect the rights of the persons concerned and are regularly monitored by us.
        <br />In addition, it may be disclosed in connection with government inquiries, court orders and legal proceedings if required for the prosecution or enforcement.
        </p>

        <h2>
            Vercel
        </h2>
        <p className="text-justify">
        This website uses the service Vercel. The provider is Vercel Inc, 340 S Lemon Ave #4133, Walnut, CA 91789, USA. Vercel is a cloud platform through which we provide our website. This means that your visit to our website is processed or passed through Vercel's servers. The data associated with your visit to our website is also transmitted to Vercel. This is necessary in order to successfully process your browser request. This data includes:
        <br />the browser type/version;
        <br />the operating system used;
        <br />the referrer URL (the previously visited page);
        <br />the IP address,
        <br />the time of the server request.
        <br />
        <br />Depending on the server location, this data may also be transferred to the USA. We have entered into an order processing agreement with Vercel. In addition, Vercel is certified under the EU-US data protection agreement and is thus obligated to comply with EU data protection requirements.
        <br />You can find Vercel's privacy policy at https://vercel.com/legal/privacy-policy.
        <br />The data processing is based on Art. 6 para. 1 lit. f DSGVO. The data transfer is necessary so that you can use our website.
        </p>

        <h2>
        Storage Period
        </h2>
        <p className="text-justify">
        As a matter of principle, we store personal data only as long as necessary to fulfill the contractual or legal obligations for which we have collected the data. Thereafter, we delete the data immediately, unless we need the data until the expiration of the statutory limitation period for evidence for civil claims or for statutory storage requirements. For evidence, we must retain contract information for three (3) years from the end of the year in which the business relationship ends with you. Any claims become statute-barred after the legal limitation period at the earliest at this time. Even after that we have to save some of your data for accounting reasons. We are obliged to do so because of legal documentation obligations which may arise from the German 14 / 12 Commercial Code, the Tax Code, the Banking Act, the Money Laundering Act and the Securities Trading Act. The deadlines for storing documents are two (2) to ten (10) years.
        </p>

        <h2>
            Your Rights
        </h2>
        <p className="text-justify">
        You have the right to request information about the processing of your personal data by us at any time. We will explain the data processing to you as part of the provision of information and provide an overview of the data stored about your person. If data stored by us should be incorrect or out-of-date, you have the right to have this information corrected. You can also request the deletion of your data. If deletion is not possible by way of exception due to other regulations, the data will be blocked so that they are only available for this legal purpose. You can also restrict the processing of your data, for example, if you believe that the information we hold is incorrect. You are also entitled to data portability. So we will send you a digital copy of the personal information you provide on request.
        <br />To exercise your rights as described here, you can always contact the above contact information. This also applies if you wish to receive copies of warranties to prove adequate data protection.
        <br />In addition, you have the right to object to data processing, which is based on Art. 6 para. 1 lit. e or f GDPR. Finally, you have the right to complain to the Data Protection Inspectorate responsible for us. You may assert this right with a regulatory authority in the Member State of your residence, your place of work or the place of alleged infringement. In Berlin, the headquarters of JMES World GmbH, the responsible supervisory authority is: Berlin Commissioner for Data Protection and Freedom of Information, Friedrichstr. 219, 10969 Berlin.
        </p>
        </>
        :
        <>
        <p className="text-justify">In dieser Datenschutzerklärung informieren wir, die JMES World GmbH, Sie über die Verarbeitung von personenbezogenen Daten bei der Nutzung unserer Website.</p>
        <p className="text-justify">Personenbezogene Daten sind Informationen, die sich auf eine identifizierte oder identifizierbare Person beziehen. Darunter fallen vor allem Informationen, die Rückschlüsse auf Ihre Identität zulassen, wie z. B. Ihr Name, Ihre Telefonnummer, Ihre Anschrift oder Ihre E-Mail-Adresse. Statistische Daten, die wir z.B. beim Besuch unserer Website erheben und die nicht mit Ihrer Person in Verbindung gebracht werden können, fallen nicht unter den Begriff "personenbezogene Daten".</p>
        <br />
        <h2>
            Kontaktperson
        </h2>
        <p className="text-justify">
        Ansprechpartner und so genannter Verantwortlicher für die Verarbeitung Ihrer personenbezogenen Daten beim Besuch dieser Website im Sinne der EU-Datenschutzgrundverordnung ("DSGVO") ist
        <br />JMES World GmbH
        <br />Rheinsberger Str. 76/77
        <br />10115 Berlin
        <br />E-Mail:info@jmesworld.com
        <br />Wenn Sie Fragen zum Datenschutz im Zusammenhang mit unseren Produkten oder der Nutzung unserer Website haben, können Sie sich jederzeit an unseren Datenschutzbeauftragten wenden. Unser Datenschutzbeauftragter ist unter der oben genannten Postanschrift sowie unter der zuvor genannten E-Mail-Adresse (Stichwort: "z.Hd. des Datenschutzbeauftragten") erreichbar.
        </p>

        <h2>
            Datenverarbeitung auf unserer Website
        </h2>
        <h3>
            1. Zugang zu unserer Website / Zugangsdaten
        </h3>
        <p className="text-justify">
        Bei jeder Nutzung unserer Website erheben wir die Zugangsdaten, die Ihr Browser automatisch übermittelt, um Ihnen den Besuch der Website zu ermöglichen. Zu den Zugangsdaten gehören insbesondere:


        <br />IP-Adresse des anfragenden Geräts;
        <br />Datum und Uhrzeit der Anfrage;
        <br />Datum und Uhrzeit der Anfrage;
        <br />Informationen über den verwendeten Browser und das Betriebssystem;
        <br />Online-Kennungen (z. B. Geräte-IDs, Sitzungs-IDs).
        </p>

        <p className="text-justify">
        Die Datenverarbeitung dieser Zugangsdaten ist erforderlich, um den Besuch der Website zu ermöglichen und die Funktionsfähigkeit und Sicherheit unserer Systeme dauerhaft zu gewährleisten. Die Zugriffsdaten werden zudem zu den oben beschriebenen Zwecken vorübergehend in internen Logfiles gespeichert, um statistische Informationen über die Nutzung unserer Website zu erhalten, um unsere Website im Hinblick auf die Nutzungsgewohnheiten unserer Besucher weiterzuentwickeln (z.B. bei steigendem Anteil mobiler Endgeräte, mit denen die Seiten abgerufen werden) und um unsere Website allgemein administrativ zu pflegen. Die Rechtsgrundlage hierfür ist Art. 6 Abs.. 1 Satz 1 lit. b GDPR.
        <br />Die in den Logfiles gespeicherten Informationen lassen keinen direkten Rückschluss auf Ihre Person zu - insbesondere speichern wir die IP-Adressen nur in verkürzter, anonymisierter Form. Die Logfiles werden für dreißig (30) Tage gespeichert und nach anschließender Anonymisierung archiviert.
        </p>

        <h3>
            2. Kontakt/Kommunikation
        </h3>
        <p className="text-justify">
        Sie haben verschiedene Möglichkeiten, mit uns in Kontakt zu treten. Dazu gehören das Kontaktformular oder die Möglichkeit, eine E-Mail zu senden. In diesem Zusammenhang verarbeiten wir Daten ausschließlich zum Zweck der Kommunikation mit Ihnen. Die Rechtsgrundlage hierfür ist Art. 6 Abs.. 1 Satz 1 lit. b GDPR. Die bei der Benutzung des Kontaktformulars von uns erhobenen Daten werden nach vollständiger Bearbeitung Ihrer Anfrage automatisch gelöscht, sofern wir Ihre Anfrage nicht noch zur Erfüllung vertraglicher oder gesetzlicher Pflichten benötigen (siehe Abschnitt "Speicherdauer").
        </p>

        <h3>
        3. Verwendung von Cookies und vergleichbaren Technologien zu Analysezwecken
        </h3>
        <p className="text-justify">
            Wir verwenden keine Cookies oder Analysetools.
        </p>

        <h2>
            Übertragung von Daten
        </h2>
        <p className="text-justify">
        Eine Übermittlung der von uns erhobenen Daten findet nur statt, wenn:
        <br /> Sie sind nach Art. 6 Abs.. 1 Satz 1 lit. a GDPR Ihre ausdrückliche Einwilligung erteilt haben;
        <br /> die Weitergabe gemäß Art. 6 Abs.. 1 Satz 1 lit. f DSGVO zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist und kein Grund zu der Annahme besteht, dass Sie ein überwiegendes Interesse an der Geheimhaltung Ihrer Daten haben;
        <br /> nach Maßgabe von Art. 6 Abs.. 1 Satz 1 lit. c GDPR sind Sie gesetzlich zur Offenlegung verpflichtet; oder
        <br /> dies gesetzlich zulässig ist und nach Art. 6 Abs.. 1 S. 1 lit. b GDPR für die Abwicklung des Vertragsverhältnisses mit Ihnen oder für die Durchführung vorvertraglicher Maßnahmen, die auf Ihren Wunsch hin durchgeführt werden, erforderlich ist.
        <br /> 
        <br /> Ein Teil der Datenverarbeitung kann von unseren Dienstleistern durchgeführt werden. Neben den in dieser Datenschutzerklärung genannten Dienstleistern können dies unter anderem Rechenzentren sein, die unsere Website und Datenbanken pflegen, IT-Dienstleister, die unsere Systeme warten, sowie Beratungsunternehmen. Wenn wir Daten an unsere Dienstleister weitergeben, dürfen diese die Daten nur zur Erfüllung ihrer Aufgaben verwenden. Die Dienstleister wurden von uns sorgfältig ausgewählt und beauftragt. Sie sind vertraglich an unsere Weisungen gebunden, verfügen über angemessene technische und organisatorische Maßnahmen zum Schutz der Rechte der betroffenen Personen und werden von uns regelmäßig kontrolliert.
        <br /> Darüber hinaus können sie im Zusammenhang mit behördlichen Ermittlungen, gerichtlichen Anordnungen und Gerichtsverfahren offengelegt werden, soweit dies für die Rechtsverfolgung oder -durchsetzung erforderlich ist.
        </p>

        <h2>
            Vercel
        </h2>
        <p className="text-justify">
        Diese Website nutzt den Dienst Vercel. Der Anbieter ist Vercel Inc, 340 S Lemon Ave #4133, Walnut, CA 91789, USA. Vercel ist eine Cloud-Plattform, über die wir unsere Website bereitstellen. Das bedeutet, dass Ihr Besuch auf unserer Website über die Server von Vercel verarbeitet oder weitergeleitet wird. Die Daten, die mit Ihrem Besuch auf unserer Website verbunden sind, werden ebenfalls an Vercel übermittelt. Dies ist notwendig, um Ihre Browser-Anfrage erfolgreich zu bearbeiten. Zu diesen Daten gehören:
        <br /> der Browsertyp/-version;
        <br /> das verwendete Betriebssystem;
        <br /> die Referrer URL (die zuvor besuchte Seite);
        <br /> die IP-Adresse,
        <br /> die Uhrzeit der Serveranfrage.
        <br /> 
        <br /> Je nach Serverstandort können diese Daten auch in die USA übertragen werden. Wir haben einen Vertrag zur Auftragsverarbeitung mit Vercel abgeschlossen. Darüber hinaus ist Vercel nach dem EU-US-Datenschutzabkommen zertifiziert und damit zur Einhaltung der EU-Datenschutzbestimmungen verpflichtet.
        <br /> Die Datenschutzbestimmungen von Vercel finden Sie unter https://vercel.com/legal/privacy-policy.
        <br /> Die Datenverarbeitung erfolgt auf Grundlage von Art. 6 Abs.. 1 lit. f DSGVO. Die Datenübermittlung ist erforderlich, damit Sie unsere Website nutzen können.
        </p>

        <h2>
        Aufbewahrungszeitraum
        </h2>
        <p className="text-justify">
        Wir speichern personenbezogene Daten grundsätzlich nur so lange, wie es zur Erfüllung der vertraglichen oder gesetzlichen Pflichten, für die wir die Daten erhoben haben, erforderlich ist. Danach löschen wir die Daten unverzüglich, es sei denn, wir benötigen die Daten bis zum Ablauf der gesetzlichen Verjährungsfrist für zivilrechtliche Ansprüche oder für gesetzliche Aufbewahrungspflichten. Zu Beweiszwecken müssen wir Vertragsdaten für drei (3) Jahre ab dem Ende des Jahres aufbewahren, in dem die Geschäftsbeziehung mit Ihnen endet. Etwaige Ansprüche verjähren frühestens nach Ablauf der gesetzlichen Verjährungsfrist zu diesem Zeitpunkt. Auch danach müssen wir einen Teil Ihrer Daten aus buchhalterischen Gründen aufbewahren. Dazu sind wir aufgrund von gesetzlichen Dokumentationspflichten verpflichtet, die sich aus dem Handelsgesetzbuch, der Abgabenordnung, dem Kreditwesengesetz, dem Geldwäschegesetz und dem Wertpapierhandelsgesetz ergeben können. Die Fristen für die Aufbewahrung der Unterlagen betragen zwei (2) bis zehn (10) Jahre.
        </p>

        <h2>
            Ihre Rechte
        </h2>
        <p className="text-justify">
        Sie haben das Recht, jederzeit Auskunft über die Verarbeitung Ihrer personenbezogenen Daten durch uns zu verlangen. Im Rahmen der Auskunftserteilung werden wir Ihnen die Datenverarbeitung erläutern und einen Überblick über die zu Ihrer Person gespeicherten Daten geben. Sollten bei uns gespeicherte Daten unrichtig oder veraltet sein, haben Sie das Recht, diese Daten berichtigen zu lassen. Sie können auch die Löschung Ihrer Daten verlangen. Sollte eine Löschung aufgrund anderer Vorschriften ausnahmsweise nicht möglich sein, werden die Daten gesperrt, so dass sie nur noch für diesen gesetzlichen Zweck zur Verfügung stehen. Sie können auch die Verarbeitung Ihrer Daten einschränken, z. B. wenn Sie der Meinung sind, dass die von uns gespeicherten Informationen falsch sind. Sie haben auch das Recht auf Datenübertragbarkeit. Wir senden Ihnen also auf Anfrage eine digitale Kopie der von Ihnen bereitgestellten personenbezogenen Daten zu.
        <br />Um Ihre hier beschriebenen Rechte auszuüben, können Sie sich jederzeit an die oben genannten Kontaktinformationen wenden. Dies gilt auch, wenn Sie Kopien von Garantien zum Nachweis eines angemessenen Datenschutzes erhalten möchten.
        <br />Darüber hinaus haben Sie ein Widerspruchsrecht gegen die Datenverarbeitung, das sich auf Art. 6 Abs.. 1 lit. e oder f GDPR beruht. Schließlich haben Sie das Recht, sich bei der für uns zuständigen Datenschutzaufsichtsbehörde zu beschweren. Dieses Recht können Sie bei einer Aufsichtsbehörde in dem Mitgliedstaat Ihres Wohnsitzes, Ihres Arbeitsplatzes oder des Ortes des mutmaßlichen Verstoßes geltend machen. In Berlin, dem Sitz der JMES World GmbH, ist die zuständige Aufsichtsbehörde: Berliner Beauftragte für Datenschutz und Informationsfreiheit, Friedrichstr. 219, 10969 Berlin.
        </p>
        </>
    }
    
    </>)
}

export { DataPrivacyContent };