import { motion, useViewportScroll } from "framer-motion";
import logoBanner from '../assets/svg/logo-banner.svg';
import logoBannerOutline from '../assets/svg/logo-banner-outline.svg';


function LogoBanner(props: {scrollPosition: number}) {
    const { scrollY } = useViewportScroll()
      

    return(
        <>
            {/* <div className='logo-banner'>
                <motion.div 
                    animate={{marginLeft: props.scrollPosition * (window.innerWidth > 700 ? 0.2 : 0.1)}}>
                    <img src={logoBannerOutline} className='first'/>
                    <img src={logoBannerOutline} className='first'/>
                    <img src={logoBannerOutline} className='first'/>
                    <img src={logoBannerOutline} className='first'/>
                    <img src={logoBannerOutline} className='first'/>
                    <img src={logoBannerOutline} className='first'/>
                    <img src={logoBannerOutline} className='first'/>
                    <img src={logoBannerOutline} className='first'/>
                    <img src={logoBannerOutline} className='first'/>
                    <img src={logoBannerOutline} className='first'/>
                </motion.div>
                
                <motion.div 
                    animate={{marginLeft: -props.scrollPosition * (window.innerWidth > 700 ? 0.2 : 0.1)}}>
                    <img src={logoBanner} className='second'/>
                    <img src={logoBanner} className='second'/>
                    <img src={logoBanner} className='second'/>
                    <img src={logoBanner} className='second'/>
                    <img src={logoBanner} className='second'/>
                    <img src={logoBanner} className='second'/>
                    <img src={logoBanner} className='second'/>
                    <img src={logoBanner} className='second'/>
                    <img src={logoBanner} className='second'/>
                    <img src={logoBanner} className='second'/>
                </motion.div>

            </div> */}

                <div className='marquee-margin'>
                    <div className="marquee">
                                    <div className="track-ltr">
                                        <div className="contents">
                                            <div>
                                                <img src={logoBannerOutline} className='first'/>
                                                <img src={logoBannerOutline} className='first'/>
                                                <img src={logoBannerOutline} className='first'/>
                                                <img src={logoBannerOutline} className='first'/>
                                                <img src={logoBannerOutline} className='first'/>
                                                <img src={logoBannerOutline} className='first'/>
                                                <img src={logoBannerOutline} className='first'/>
                                                <img src={logoBannerOutline} className='first'/>
                                                <img src={logoBannerOutline} className='first'/>
                                                <img src={logoBannerOutline} className='first'/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="marquee">
                                <div className="track-rlt">
                                    <div className="contents">
                                        <div>
                                            <img src={logoBanner} className='second'/>
                                            <img src={logoBanner} className='second'/>
                                            <img src={logoBanner} className='second'/>
                                            <img src={logoBanner} className='second'/>
                                            <img src={logoBanner} className='second'/>
                                            <img src={logoBanner} className='second'/>
                                            <img src={logoBanner} className='second'/>
                                            <img src={logoBanner} className='second'/>
                                            <img src={logoBanner} className='second'/>
                                            <img src={logoBanner} className='second'/>
                                        </div>
                                    </div>
                                </div>
                        </div>
                </div>
           
        </>
    )
}

export {LogoBanner};