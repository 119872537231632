
import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";

function TeamMember(props: any) {
    const controls = useAnimation();
    const { ref, inView } = useInView();

    const { selectedLanguage, scaleDown, scaleUp } = props;
    
    useEffect(() => {
        if (inView) {
          controls.start('visible');
        }
      }, [controls, inView]);

    const variants = {
        visible: { opacity: 1, translateY: 0 },
        hidden: { opacity: 0, translateY: 50 }
      };

    return(
        <>
        <motion.div 
        ref={ref}
        className='team-member' 
        key={props.element.name}
        
        initial="hidden"
        animate={props.controls}
        transition={{ duration: 0.8, delay: 0.8}}
        variants={props.variants}
        >
            <div>
                <img src={props.element.image} style={{transform: `scale(${scaleDown ? 0.7 : scaleUp ? 1.1 : 1})`}}/>
                { props.imageBackground ? <div className="image-background" /> : '' }
                <div className="text">
                    <h2>
                        { props.element.name }
                    </h2>
                    {
                        (selectedLanguage === "de" && props.element.positions_de) ?
                        props.element.positions_de.map((position: string) => (
                            <p>
                                {position}
                            </p>
                        )) :
                        props.element.positions.map((position: string) => (
                            <p>
                                {position}
                            </p>
                        ))
                    }
                    { props.imageBackground ? '' : <div className="background" /> }
                </div>
            </div>

        </motion.div>
        </>
    )
}

export { TeamMember }