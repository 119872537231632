import drop_001 from '../assets/img/drops/001.mp4';
import drop_002 from '../assets/img/drops/002.mp4';
import drop_003 from '../assets/img/drops/003.mp4';

interface LatestDropsElement {
    title: string;
    image: string;
    url: string;
}

let LATEST_DROPS_ELEMENTS: LatestDropsElement[] = [
    {
        title: "JMES x Super A - Day",
        image: drop_002,
        url: "https://super-a.jmesworld.com/?theme=white"
    },
    {
        title: "JMES x Super A - Night",
        image: drop_001,
        url: "https://super-a.jmesworld.com/?theme=black"
    },
    {
        title: "Coming soon",
        image: drop_003,
        url: "#latest-drops"
    }
];

export { LATEST_DROPS_ELEMENTS };
export type { LatestDropsElement };
