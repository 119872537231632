import { useParams } from "react-router-dom";
//import CSS, {AtRule}  from 'csstype';
import {style, media} from "typestyle";
//import * as data from './../assets/uuids.json';

function NftContent(props: any) {
    const {language, id, nftData} = props;
    
    /*const Astyles: CSS.Properties = {
        marginTop: '70px'
        
    };*/

    const secButton = style({
        marginTop: '70px'
    },
    media({maxHeight:450}, {marginTop: '50px'}));
    
    
    /*var test = "";
    if (id === "Hallo") {
        test = "go!";
    }*/

    return(
        <>
        {
        language === "en" ? 
        <>
        <br />
        <br />
        <br />
        <br />
        {
            
                    /*<p className="text-justify">Englisch: {nftData.id}</p>*/
        
            /*<p className="text-justify">Englisch: {test}</p>*/
        }
        {

        }
        <div className="nft-buttons">        
            <div>
                <a href={nftData.links[0].link}>
                    <button className="nft-button">{nftData.links[0].titel}</button>
                </a><br />
            </div>
            <div className={secButton} >
                <a href={nftData.links[1].link} >
                    <button className="nft-button">{nftData.links[1].titel}</button>
                </a>
            </div>
        </div>

        
        
        </>
        :
        <>
        <br />
        <br />
        <br />
        <br />
        <a href={nftData.links[0].link}>
            <button className="nft-button">{nftData.links[0].titel}</button>
        </a><br />
        <a href={nftData.links[1].link}>
            <button className="nft-button">{nftData.links[1].titel}</button>
        </a>
        {/*<p className="text-justify">Deutsch: {id}</p>*/}
        </>
        }
    </>
        
    );
}

export { NftContent };