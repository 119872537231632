import * as React from "react";
import NextDrops_en from "./../assets/img/header/next-drops-en.png";
import NextDrops_de from "./../assets/img/header/next-drops-de.png";

import WhatIsJMES_en from "./../assets/img/header/what-is-jmes-en.png";
import WhatIsJMES_de from "./../assets/img/header/what-is-jmes-de.png";

import HowDoesItWork_en from "./../assets/img/header/how-does-it-work-en.png";
import HowDoesItWork_de from "./../assets/img/header/how-does-it-work-de.png";

import NextDrop_en from "./../assets/img/header/next-drop-en.png";
import NextDrop_de from "./../assets/img/header/next-drop-de.png";

import FirstDrop from "./../assets/img/header/first-drop.png";

import WhatsNext_en from "./../assets/img/header/whats-next-en.png";
import WhatsNext_de from "./../assets/img/header/whats-next-de.png";

import Events_en from "./../assets/img/header/events.png";

import Impact_en from "./../assets/img/header/impact.png";

import Team_en from "./../assets/img/header/team.png";

import Imprint_en from "./../assets/img/header/imprint-en.png";
import Imprint_de from "./../assets/img/header/imprint-de.png";

import Privacy_en from "./../assets/img/header/privacy-en.png";
import Privacy_de from "./../assets/img/header/privacy-de.png";

import JMES_World_en from "./../assets/img/header/JMES-world-en.png";

function SectionHeader(props: any) {
    const { sectionTitle, selectedLanguage,isPageHeader } = props;
    let imageURL = "";

    switch (sectionTitle) {
        case "NextDrops":
                imageURL = (selectedLanguage === "de" ? NextDrops_de : NextDrops_en);
            break;
        case "WhatIsJMES":
                imageURL = (selectedLanguage === "de" ? WhatIsJMES_de : WhatIsJMES_en);
            break;
        case "HowDoesItWork":
                imageURL = (selectedLanguage === "de" ? HowDoesItWork_de : HowDoesItWork_en);
            break;
        case "NextDrop":
                imageURL = (selectedLanguage === "de" ? FirstDrop : FirstDrop);
            break;
        case "WhatsNext":
                imageURL = (selectedLanguage === "de" ? WhatsNext_de : WhatsNext_en);
            break;
        case "Events": 
                imageURL = Events_en;
            break;
        case "Impact": 
            imageURL = Impact_en;
            break;
        case "Team": 
            imageURL = Team_en;
            break;
        case "Imprint": 
            imageURL = (selectedLanguage === "de" ? Imprint_de : Imprint_en);
            break;
        case "Privacy": 
            imageURL = (selectedLanguage === "de" ? Privacy_de : Privacy_en);
            break;
        case "JMESWorld": 
            imageURL = JMES_World_en;
            break;
        default:
            break;
    }

    return(imageURL ? <img src={imageURL} className={"section-header" + (isPageHeader ? " page-header" : "")} alt={sectionTitle} /> : null);
}

export { SectionHeader };