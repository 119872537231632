const calculateTimeLeftTillNextDrop = () => {
    let difference = +new Date(`03/21/2022`) - +new Date();
  
    let timeLeft: any = {};
  
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)).toString().padStart(2, '0'),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24).toString().padStart(2, '0'),
        minutes: Math.floor((difference / 1000 / 60) % 60).toString().padStart(2, '0'),
        seconds: Math.floor((difference / 1000) % 60).toString().padStart(2, '0')
      };
    } else {
        timeLeft = {
            days: "0",
            hours: "00",
            minutes: "00",
            seconds: "00"
        }
    }
    return timeLeft;
  }

  export { calculateTimeLeftTillNextDrop }