
import { motion, useAnimation, useCycle } from "framer-motion";
import { Suspense, useState } from "react";
import drop from '../assets/img/content/drop.png';

import { StarIcon } from "./3D/Drop";


function ImpactContent(props: any) {
    const {impactEntries} = props;
    const [modalInfo, setModalInfo] = useState({ title: "", description: "" });
    
    const variants = {
        visible: { opacity: [0, 1, 1, 1, 0], translateY: window.innerHeight / 2},
        hidden: { translateY: 0 }
      };
    return(
        <>
        {/* <Suspense fallback={null}>
          <StarIcon isHover={false} isLiked={false} />
        </Suspense> */}
        
        <div className='drop-wrapper'>
        {
            impactEntries.map((entry: {title: string, description: string},index: number) => (
                <motion.div className='drop'
                    key={'drop-' + index }
                    initial="hidden"
                    animate="visible"
                    whileHover="hover"
                    transition={{ duration: (7 + (index / (impactEntries.length))), delay: (index % 2 || index === 0) ? 0 : (1 / index + 1), repeat: Infinity}}
                    variants={variants}
                    onClick={() => {setModalInfo(entry)}}>
                    <img src={drop} />
                    <p className='title'>{entry.title}</p>
                    {/* <p>{entry.description}</p> */}
                </motion.div>
            ))
        }
        {
            impactEntries.map((entry: {title: string, description: string},index: number) => (
                <div className='drop-mobile'
                    key={'drop-mobile-' + index }
                    onClick={() => {setModalInfo(entry)}}>
                    <img src={drop} />
                    <p className='title'>{entry.title}</p>
                    {/* <p>{entry.description}</p> */}
                </div>
            ))
        }
        </div>
        { modalInfo.title ?
            <motion.div 
                className="drop-modal"
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                transition={{duration: .5}}
                onClick={() => setModalInfo({ title: "", description: "" })}>
               <motion.div className='drop'
                    key="drop-opened"
                    initial={{opacity: 0, translateY: -300}}
                    animate={{opacity: 1, translateY: (window.innerHeight - 442) / 2}}
                    transition={{duration: 2}}>
                    <img src={drop} />
                    <p className="description">{modalInfo.description}</p>
                </motion.div>
            </motion.div>
            :
            ''
        }
        </>
    )
}

export { ImpactContent };